import React from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";

import { AppBar, Box, Typography } from "@mui/material";

import "../css/AppBar.css";

import HorizontalLogo from "../assets/images/hlogo2.png";

import FarmerCropsPageComponent from "./Crops/FarmerCropsPageComponent";
import DashboardComponent from "./DashboardComponent";
import FarmWideInformationComponent from "./FarmWideInformationComponent";
import FarmerLanduseComponent from "./FarmerLanduseComponent";
import FarmerOnboardingStepsComponent from "./FarmerOnboardingStepsComponent";
import InFarmLivestockComponent from "./InFarm/InFarmLivestockComponent";
import OutFarmLivestockComponent from "./OutFarmLivestockComponent";
import PageNotFoundComponent from "./PageNotFoundComponent";

import Step2IndirectFuelUsageComponent from "./IndirectFuel/Step2IndirectFuelUsageComponent";
import Step1FarmInfoComponent from "./Step1FarmInfoComponent";
import Step2EnergyUsageComponent from "./Step2EnergyUsageComponent";


import { SignedIn, UserButton } from "@clerk/clerk-react";
import MapParcelsComponent from "./MapAndParcels/MapParcelsComponent";
import VerificationDocumentsComponent from "./VerificationDocumentsComponent";
import LanguageChange from "./general/LanguageChange";


const AppBarComponent = (props) => {


  const location = useLocation()

  const accountSettings = useSelector((state) => state.accountSettingsReducer);
  const isAdmin = accountSettings?.[0]?.role === 'admin'
  const isCarbonPlusPath = location?.pathname?.includes('/carbonplus')



  const currentFarmDataObject = useSelector(
    (state) => state.currentFarmDataReducer
  );

  return (
    <>
      <AppBar style={{ backgroundColor: "#409781" }}>
        <Box display='flex' alignItems='center' justifyContent='space-between' sx={{ my: 1, pl: 1 }}>
          <Link to={{
            pathname: "/carbonplus/"
          }}>
            <img
              alt="Climate Farmers Logo"
              src={HorizontalLogo}
              height={40}
              width={120}
            />
          </Link>
          <Box display='flex' alignItems='center' justifyContent='space-between' gap={2} sx={{ mr: 4 }}>
            {(isAdmin && isCarbonPlusPath) || <Typography variant='subtitle2' >
              {currentFarmDataObject?.farmInfo.farmName}
            </Typography>}
            <SignedIn>
              <UserButton />
            </SignedIn>
            <LanguageChange />
          </Box>
        </Box>
      </AppBar >


      <Routes>
        <Route path="/carbonplus" exact element={<DashboardComponent />} />
        <Route
          path="/carbonplus/farm"
          exact
          element={<Step1FarmInfoComponent />}
        />

        <Route
          path="/carbonplus/fuel/direct"
          exact
          element={<Step2EnergyUsageComponent />}
        />
        <Route
          path="/carbonplus/fuel/indirect"
          exact
          element={<Step2IndirectFuelUsageComponent />}
        />
        <Route
          path="/carbonplus/livestock"
          exact
          element={<InFarmLivestockComponent />}
        />
        <Route
          path="/carbonplus/livestock/outfarm"
          exact
          element={<OutFarmLivestockComponent />}
        />
        <Route
          path="/carbonplus/landuse"
          exact
          element={<MapParcelsComponent />}
        />
        <Route
          path="/carbonplus/farmwideinformation"
          exact
          element={<FarmWideInformationComponent />}
        />
        <Route
          path="/carbonplus/farmerlanduse/:parcelid"
          exact
          element={<FarmerLanduseComponent />}
        />
        <Route
          path="/carbonplus/farmercropspage/:parcelid"
          exact
          element={<FarmerCropsPageComponent />}
        />

        <Route
          path="/carbonplus/verificationdocuments"
          exact
          element={<VerificationDocumentsComponent />}
        />
        <Route path="/farmland" element={<FarmerOnboardingStepsComponent />} />

        <Route
          path="/"
          element={<Navigate to="/carbonplus" replace></Navigate>}
        ></Route>
        {/* only match this when no other routes match */}
        <Route path="*" element={<PageNotFoundComponent />} />
      </Routes>

    </>
  );
};

export default AppBarComponent;
